<script setup>
import { ref } from "vue";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

// import FurtherHelpSection from "./FurtherHelpSection.vue";
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";
import ClientElement from "./ClientElement.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

// const showModal = ref(false);
// const productList = ref(clientConfig.content.products);
const products = ref(null);
// const scrolledToProducts = useElementVisibility(products);
const currentSlide = ref(null);
const videoID = Math.floor(Math.random() * 5);

const reviews = [
  "Pen went above and beyond to find a Property solution for us. They provided the quote within a few hours, which was greatly appreciated. The policy was bound a couple of days later. Very happy with the outcome.",
  "We really love dealing with Pen at the moment. The Transport guys are great to deal with, rates are competitive, and claims are usually good.",
  "We've had some success with the Property Team recently too. The Hospitality guys are always great to deal with. Loving your work!",
];

const clients = [
  { altText: "Lloyds", url: "lloyds-logo.png" },
  { altText: "Swiss Re", url: "swiss-logo.png" },
  { altText: "Berkley Re", url: "berkley-logo.png" },
  { altText: "AXA", url: "axa-logo.png" },
  { altText: "Allied World", url: "allied-logo.png" },
  { altText: "HDI", url: "hdi-logo.png" },
];

function slideTo(val) {
  this.currentSlide = val;
}

const breakpoints = {
  // 700px and up
  768: {
    itemsToShow: 2.5,
  },
  // 1024 and up
  992: {
    itemsToShow: 5,
  },
};

const breakpoints2 = {
  // 700px and up
  768: {
    itemsToShow: 1,
    snapAlign: "start",
  },
  // 1024 and up
  992: {
    itemsToShow: 2,
    snapAlign: "start",
  },
};
</script>

<template>
  <div>
    <div class="side-triangle" data-aos="zoom-in" data-aos-delay="200"></div>
    <HeaderSection class="homepage" />
    <section
      id="hero"
      class="home"
      ref="home"
      data-aos="zoom-in"
      data-aos-delay="200"
    >
      <video
        class="background-video-hosted html5-video d-none"
        :class="videoID == 0 ? 'd-lg-block' : ''"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        preload="auto"
        src="@/assets/video/sydney-small.mp4"
        poster="@/assets/video/sydney-poster.jpg"
      >
        <meta itemprop="name" content="Pen Underwriting Sydney" />
        <meta
          itemprop="description"
          content="Pen Underwriting Sydney - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
        />
      </video>

      <video
        class="background-video-hosted html5-video d-none"
        :class="videoID == 1 ? 'd-lg-block' : ''"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        preload="auto"
        src="@/assets/video/melbourne-small.mp4"
        poster="@/assets/video/melbourne-poster.jpg"
      >
        <meta itemprop="name" content="Pen Underwriting Melbourne" />
        <meta
          itemprop="description"
          content="Pen Underwriting Melbourne - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
        />
      </video>

      <video
        class="background-video-hosted html5-video d-none"
        :class="videoID == 2 ? 'd-lg-block' : ''"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        preload="auto"
        src="@/assets/video/brisbane-small.mp4"
        poster="@/assets/video/brisbane-poster.jpg"
      >
        <meta itemprop="name" content="Pen Underwriting Brisbane" />
        <meta
          itemprop="description"
          content="Pen Underwriting Brisbane - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
        />
      </video>

      <video
        class="background-video-hosted html5-video d-none"
        :class="videoID == 3 ? 'd-lg-block' : ''"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        preload="auto"
        src="@/assets/video/coastal-small.mp4"
        poster="@/assets/video/coastal-poster.jpg"
      >
        <meta itemprop="name" content="Pen Underwriting Coastal" />
        <meta
          itemprop="description"
          content="Pen Underwriting Coastal - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
        />
      </video>

      <video
        class="background-video-hosted html5-video d-none"
        :class="videoID == 4 ? 'd-lg-block' : ''"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        preload="auto"
        src="@/assets/video/regional-small.mp4"
        poster="@/assets/video/regional-poster.jpg"
      >
        <meta itemprop="name" content="Pen Underwriting regional" />
        <meta
          itemprop="description"
          content="Pen Underwriting regional - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
        />
      </video>

      <div class="d-none d-lg-block shape-menu-bg"></div>
      <div class="d-none d-lg-block hero-mask"></div>

      <div class="container">
        <div class="row">
          <div class="col order-1 order-lg-1 hero-img pl-5">
            <div class="row hero-tag">
              <div>
                <img
                  class="hero-logo d-none d-lg-block"
                  src="@/assets/img/pen/pen-logo.svg"
                  alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                />
                <img
                  class="hero-logo-mobile d-block d-lg-none"
                  src="@/assets/img/pen/penlogosmall-purple.svg"
                  alt="Pen Underwriting - Transport Insurance, D&O Insurance, Professional Risk Insurance, Financial Lines Insurance, Property Insurance, Care Liability Insurance, Hospitality Liability Insurance, General Liability Insurance, Construction Insurance"
                />
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <h1 class="highlight">Insurance that evolves with you</h1>
                <h2>
                  Partnering with you to deliver innovative insurance solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="subheadings"
      class="subheadings"
      data-aos="zoom-in"
      data-aos-delay="200"
    >
      <div class="container">
        <div class="row actions">
          <div class="col text-center">
            <span class="cursive">Protecting</span> Businesses
          </div>
          <span class="d-none d-sm-block">|</span>
          <div class="col text-center">
            Growth by <span class="cursive">Partnerships</span>
          </div>
          <span class="d-none d-sm-block">|</span>
          <div class="col text-center">
            Innovative <span class="cursive">Solutions</span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="side-banner row">
          <div class="col-lg-4 col-12 title">
            <p>Power of Pen:</p>
            <h4>A Decade of History,<br />a Millenium<br />of Experience</h4>
          </div>

          <div class="col-lg-5 col-12">
            <p>
              If you’re seeking a reliable and experienced underwriting partner,
              whether you’re a broker, an Insurer, or someone interested in
              joining our team, we invite you to explore Pen Underwriting.
            </p>
            <router-link to="/about-pen" class="white-button highlight"
              >Explore Pen</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section
      id="who-we-are"
      class="who-we-are"
      data-aos="zoom-in"
      data-aos-delay="200"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4 team-pic"></div>
          <div class="col-12 col-lg-3 heading">
            <h3>Our Team</h3>
            <p class="lead">
              Growth by <span class="cursive">Partnerships</span>
            </p>
          </div>
          <div class="col-12 col-lg-5 description">
            <p>
              Our people are the bedrock of our business and we cultivate a
              culture of respect, integrity, inclusiveness and professionalism.
              <br /><br />
              Working with over 500 broking businesses and thousands of brokers,
              we strive to deliver consistent quality service and go beyond our
              customers’ expectations by being helpful and creative throughout
              the process. <br /><br />
              <router-link to="/contact" class="purple-button"
                >Get In Touch</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="products" class="section products" ref="products">
      <div class="container text-center" data-aos="zoom-in">
        <h3 class="highlight text-center">Products & Services</h3>
        <div class="row xs-justify-content-center g-3 product-wrapper">
          <router-link
            to="/construction-overview"
            class="col-4 col-md-4 products-images construction"
            >Get In Touch
            <div class="mask mask-1"></div>
            <h4 class="highlight">Construction</h4>
          </router-link>
          <router-link
            class="col-4 col-md-4 products-images transport"
            to="/transport-overview"
          >
            <div class="mask mask-2"></div>
            <h4 class="highlight">Transport</h4>
          </router-link>
          <router-link
            class="col-4 col-md-4 products-images risk"
            to="/financial-lines"
          >
            <div class="mask mask-3"></div>
            <h4 class="highlight">Financial Lines</h4>
          </router-link>
          <router-link
            class="col-4 col-md-4 products-images property"
            to="/property-overview"
          >
            <div class="mask mask-4"></div>
            <h4 class="highlight">Property</h4>
          </router-link>
          <router-link
            class="col-4 col-md-4 products-images hospitality"
            to="/general-liability-overview"
          >
            <div class="mask mask-5 left"></div>
            <h4 class="highlight">Liability</h4>
          </router-link>
          <router-link
            class="col-4 col-md-4 products-images care-liability"
            to="/overview-care-liability"
          >
            <div class="mask mask-6"></div>
            <h4 class="highlight">Care Liability</h4>
          </router-link>
        </div>
        <router-link to="/construction-overview" class="highlight">
        </router-link>
      </div>
    </section>

    <section id="partners" class="partners" ref="partners">
      <h3>Our Partners</h3>
      <p>
        Our capacity partners are international and domestic insurance and
        reinsurance companies. Each partner has a minimum A rating (AM Best
        and/or Standard & Poor’s)
      </p>

      <Carousel
        id="thumbnails"
        :wrap-around="true"
        v-model="currentSlide"
        ref="carousel"
        :autoplay="2000"
        :breakpoints="breakpoints"
      >
        <Slide v-for="client in clients" :key="client">
          <div class="carousel__item" @click="slideTo(slide - 1)">
            <ClientElement :altText="client.altText" :url="client.url" />
          </div>
        </Slide>
      </Carousel>
    </section>

    <section id="clientReviews" class="clientReviews" ref="clientReviews">
      <h4 class="highlight">What people are saying about us</h4>
      <Carousel :breakpoints="breakpoints2" :wrap-around="true">
        <Slide v-for="review in reviews" :key="review">
          <div class="carousel__item">
            <p class="highlight">{{ review }}</p>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
          <Navigation />
        </template>
      </Carousel>
    </section>

    <!-- <FurtherHelpSection /> -->

    <FooterSection />
  </div>
</template>
